import { useTranslation } from 'react-i18next';

type Props = object;

export default function EezyPayIntroduction(_: Props) {
    const { t } = useTranslation();
    const content: { img: string; title: string; desc: string; w: number; h: number }[] = [
        {
            img: '/imgs/eezy/1_instruct_create.png',
            title: t('eezy-pay-instruction.step-1'),
            desc: t('eezy-pay-instruction.step-1-desc'),
            w: 130,
            h: 88,
        },
        {
            img: '/imgs/eezy/2_share.png',
            title: t('eezy-pay-instruction.step-2'),
            desc: t('eezy-pay-instruction.step-2-desc'),
            w: 204,
            h: 62,
        },
        {
            img: '/imgs/eezy/3_pay.png',
            title: t('eezy-pay-instruction.step-3'),
            desc: t('eezy-pay-instruction.step-3-desc'),
            w: 118,
            h: 90,
        },
        {
            img: '/imgs/eezy/4_receive.png',
            title: t('eezy-pay-instruction.step-4'),
            desc: t('eezy-pay-instruction.step-4-desc'),
            w: 90,
            h: 82,
        },
    ];

    return (
        <div className="grid xl:grid-cols-2 gap-y-10 gap-x-16">
            {content.map((i, index) => (
                <div key={i.title} className="flex flex-col gap-3 items-center">
                    <div className="h-[136px] grid items-center">
                        <img src={i.img} width={i.w} height={i.h} alt={i.title} />
                    </div>
                    <h4 className="font-semibold text-base text-gray-800 text-center leading-normal">
                        {index + 1}. {i.title}
                    </h4>
                    <p className="text-base text-xs text-gray-800 text-center leading-normal">{i.desc}</p>
                </div>
            ))}
        </div>
    );
}
