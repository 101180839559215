import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type React from 'react';
import { forwardRef } from 'react';
import { cn } from 'utils';

type Icon = Parameters<typeof FontAwesomeIcon>[0]['icon'];
type ButtonVariant = 'filled' | 'outline' | 'ghost';
type ButtonColor = 'violet' | 'green' | 'red';

type ButtonProps = {
    children: React.ReactNode;
    variant?: ButtonVariant;
    color?: ButtonColor;
    size?: 'large' | 'medium' | 'small';
    disabled?: boolean;
    loading?: boolean;
    startIcon?: Icon;
    endIcon?: Icon;
    className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            startIcon,
            endIcon,
            disabled,
            loading,
            variant = 'filled',
            color = 'violet',
            size = 'medium',
            className,
            ...props
        }: ButtonProps,
        forwardRef,
    ) => {
        return (
            <button
                {...props}
                ref={forwardRef}
                className={cn(
                    'text-white rounded-full transition-all inline-flex items-center justify-center gap-2 min-w-[80px] tg-body-bold',
                    disabled && 'opacity-40 cursor-default pointer-events-none',
                    {
                        filled: {
                            violet: 'bg-violet-800 hover:bg-violet-700 active:bg-violet-800',
                            green: 'bg-green-600 hover:bg-green-500',
                            red: 'bg-red-900 hover:bg-red-800',
                        }[color],
                        outline:
                            'border border-gray-200 text-gray-800 hover:text-black hover:border-gray-300 active:bg-gray-50 bg-white',
                        ghost: 'text-gray-800 hover:text-black hover:bg-gray-100 active:bg-gray-200',
                    }[variant],
                    {
                        large: 'h-[52px] px-6 md:h-11 md:px-5',
                        medium: 'h-11 px-5 md:h-10 md:px-4',
                        small: 'h-10 px-4 md:h-9 md:px-[14px]',
                    }[size],
                    className,
                )}
            >
                {startIcon && <FontAwesomeIcon icon={startIcon} size={size === 'medium' ? 'lg' : 'sm'} />}
                {children}
                {endIcon && <FontAwesomeIcon icon={endIcon} />}
                {loading && <FontAwesomeIcon icon={['fas', 'spinner-third']} className="animate-spin" />}
            </button>
        );
    },
);
