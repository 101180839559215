import type { ReactNode } from 'react';
import styled from 'styled-components';
import { PAGEWIDTH_MAX } from '../../styles/variables';

const PageContentWrapper = styled.div`
    border-radius: 0 0 10px 10px;

    @media (min-width: ${PAGEWIDTH_MAX}px) {
        width: ${PAGEWIDTH_MAX}px;
    }
`;

interface IPurpleBackgroundProps {
    children: ReactNode;
    fadeOff?: boolean;
}
const PurpleBackground = (props: IPurpleBackgroundProps) => {
    return (
        <div className="flex justify-center bg-violet-100 relative">
            <PageContentWrapper className="flex w-full">{props.children}</PageContentWrapper>
        </div>
    );
};

export default PurpleBackground;
