import moment from 'moment';
import * as MomentRange from 'moment-range';
import * as R from 'ramda';
import type React from 'react';
import { trans } from '../';
import type { IFile } from '../../../../shared/src/types/common';
import type {
    IAmountWithVat,
    IAllowance,
    IDatePair,
    IReceiptCost,
    IReceiptCostInput,
    ITravel,
    ITravelInput,
    CostStatus,
    ICostInvoice,
    InputAllowance,
} from '../../../../shared/src/types/costs';
import type { IInvoice, InvoiceTemplate, IContract } from '../../../../shared/src/types/invoice';
import type { UiLanguage } from '../../../../shared/src/types/user';
import PoweredByGoogle from '../../assets/images/powered_by_google_on_white.png';
import type { IDropdownOption } from '../../components/form/AutocompleteDropdown';
import { Icon } from '../../components/Icon';
import {
    CostTypeSelection,
    ReceiptDetails,
    ReceiptUpload,
    TravelAllowances,
    TravelCosts,
    TravelDistance,
    TravelDistanceCalculator,
    TravelMap,
    TravelRound,
    TravelRoute,
    TravelTime,
    TravelTypeSelection,
} from '../../containers/costs';
import { CostReceiptType } from '../../containers/costs/CostReceiptType';
import type { IUiFile } from '../../containers/costs/ReceiptUpload';
import { TravelReceiptType } from '../../containers/costs/TravelReceiptType';
import countriesEn from '../../locales/en/countries.json';
import countriesFi from '../../locales/fi/countries.json';
import {
    COLOR_GREYJOY,
    COLOR_IMPORTANT,
    COLOR_STATUS_DONE,
    COLOR_STATUS_WAITING,
} from '../../styles/variables';
import { divide, multiply, round, vatFactor } from '../calc';
import type { IDirectionsResult } from '../mapsUtils';
import {
    addDayToDatetime,
    dateOrDefault,
    formatDate,
    formatDateISO,
    hoursBetween,
    xNotAfterYInDays,
} from '../time';
import { formatValidationResult } from '../validation';
import validators from './validators';
import { CarRegistrationNumber } from 'containers/costs/CarRegistrationNumber';
import { CostDocumentContractInfo } from 'containers/costs/CostDocumentContractInfo';

// https://stackoverflow.com/questions/70335691/moment-not-extendable-with-moment-range
// @ts-expect-error moment-range is 6 years old and should be scrapped at some point
const rangeMoment = MomentRange.extendMoment(moment);

export const ANKKANET_ID_LIMIT = 10000000;

// currently edited travel receipt
interface ITravelCostTemp {
    id?: number;
    isNew?: boolean; // adding totally new cost under travel
    errors?: Record<number, unknown>;
    cost?: IReceiptCost;
    tempId?: number; // the index id for newly created travel cost
}

export interface IRound {
    destination: string;
    origin: string;
}

export interface ICostState {
    addressSuggestions?: { client?: string; home?: string };
    client?: string;
    home?: string;
    currentCost?: IReceiptCost;
    currentTravel?: ITravel;
    errors?: Record<number, unknown>; // errors for each step
    invoice?: IInvoice;
    invoiceTemplate: InvoiceTemplate;
    invoicingTravelTotalWithoutVat?: number; // total of travel rows on invoice
    language: UiLanguage;
    prevSteps: number[];
    step: number;
    travelCostTemp?: ITravelCostTemp;
    travelRoundDirections?: {
        route: IRound;
        directions?: IDirectionsResult;
    };
    travelRouteDirections?: { route: string[]; directions?: IDirectionsResult };
    travelRound: IRound;
    travelRoute: string[];
    carNumber?: string;
    saveCarNumber?: boolean;
    hasContract?: boolean;
    isLoadingContract?: boolean;
}

export interface ICostComponentProps extends ICostState {
    dispatch: React.Dispatch<unknown>;
    costLogicDispatch: React.Dispatch<unknown>;
}

export const COST_RECEIPT_FILETYPES = '.pdf, image/jpeg, image/png, .tiff';
export const COST_VAT_PERCENTAGES = (() => {
    const now = new Date();
    const newVAT = new Date(2024, 7, 14); // 14 Aug 2024 will show new VAT rate
    const removeOldVAT = new Date(2025, 2, 1); // 1 Mar 2025 will remove old VAT rate

    const rates = [0, 10, 14];

    if (now < removeOldVAT) {
        rates.push(24);
    }

    if (now >= newVAT) {
        rates.push(25.5);
    }

    return rates;
})();

const DEFAULT_VAT = (() => {
    const now = new Date();
    const newVAT = new Date(2024, 8, 1); // 1 Sep 2024 will use new VAT rate as default

    if (now >= newVAT) {
        return 25.5;
    }

    return 24;
})();

export const [
    START,
    MATERIAL,
    TRAVEL_CAR_ROUND,
    TRAVEL_CAR_ROUTE,
    TRAVEL_CAR_MAP,
    TRAVEL_OTHER_ROUND,
    TRAVEL_OTHER_ROUTE,
    RECEIPT,
] = [0, 1, 2, 3, 4, 5, 6, 7];

export const getInitialCost = (withoutVAT: boolean): IReceiptCost => ({
    amountsWithVats: [{ amount: 0, vatPercent: withoutVAT ? 0 : DEFAULT_VAT }],
    description: trans('costs.costReceiptType.supply'),
    id: 0,
    purchaseDate: '',
    receiptFiles: [],
    status: 'incomplete',
    type: 'cost',
});

export const INITIAL_TRAVEL_COST: IReceiptCost = {
    amountsWithVats: [{ amount: 0, vatPercent: DEFAULT_VAT }],
    description: '',
    id: 0,
    purchaseDate: '',
    receiptFiles: [],
    status: 'incomplete',
    type: 'cost',
};

export const INITIAL_TRAVEL: ITravel = {
    allowancesTotal: 0,
    dailyAllowancesFull: {
        quantity: 0,
        salaryType: 'domestic-allowance',
    },
    dailyAllowancesHalf: {
        quantity: 0,
    },
    endTime: '',
    id: 0,
    receiptCosts: [],
    reimbursedKMs: {
        quantity: 0,
    },
    reimbursedMeals: {
        quantity: 0,
    },
    reimbursedTrailerKMs: {
        quantity: 0,
    },
    route: [],
    startTime: '',
    taxable: false,
    totalKMs: 0,
    type: 'travel',
    carNumber: '',
};

export const getStepTitle = (step: number) => {
    switch (step) {
        case START:
            return trans('costs.attach-receipt');
        case MATERIAL:
        case RECEIPT:
            return trans('costs.add-receipt');
        case TRAVEL_CAR_ROUND:
        case TRAVEL_CAR_ROUTE:
        case TRAVEL_CAR_MAP:
            return trans('costs.travel-by-car');
        case TRAVEL_OTHER_ROUND:
        case TRAVEL_OTHER_ROUTE:
            return trans('costs.travel-other');
        default:
            return undefined;
    }
};

const Logo = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <img src={PoweredByGoogle} alt="Powered by Google" width={100} />
        </div>
    );
};

export const getStepContent = (state: ICostState, dispatch: React.Dispatch<unknown>): React.ReactNode[] => {
    const defaultProps: ICostComponentProps = {
        ...state,
        dispatch,
        costLogicDispatch: dispatch,
    };

    switch (state.step) {
        case START:
            return [<CostTypeSelection key="costTypeSelection" {...defaultProps} />];
        case MATERIAL:
            return [
                <CostDocumentContractInfo
                    key="contractInfo"
                    hasContract={state.hasContract}
                    isLoadingContract={state.isLoadingContract}
                />,
                <CostReceiptType key="costReceiptType" {...defaultProps} />,
                <ReceiptDetails key="receiptDetails" {...defaultProps} />,
                <ReceiptUpload key="receiptUpload" {...defaultProps} hasContract={state.hasContract} />,
            ];
        case RECEIPT:
            return [
                <CostDocumentContractInfo
                    key="contractInfo"
                    hasContract={state.hasContract}
                    isLoadingContract={state.isLoadingContract}
                />,
                <TravelReceiptType key="travelReceiptType" {...defaultProps} />,
                <ReceiptDetails key="receiptDetails" {...defaultProps} />,
                <ReceiptUpload key="receiptUpload" {...defaultProps} hasContract={state.hasContract} />,
            ];
        case TRAVEL_CAR_ROUND:
            return [
                <TravelTypeSelection key="travelTypeSelection" {...defaultProps} />,
                <TravelRound key="travelRound" {...defaultProps} />,
                <TravelTime key="travelTime" {...defaultProps} />,
                <TravelMap key="travelMap" {...defaultProps} />,
                <CarRegistrationNumber key="carRegistrationNumber" {...defaultProps} />,
                <TravelDistance key="travelDistance" {...defaultProps} />,
                <TravelDistanceCalculator key="travelDistanceCalculator" {...defaultProps} />,
                <TravelCosts key="travelCosts" {...defaultProps} />,
                <TravelAllowances key="travelAllowances" {...defaultProps} />,
                <Logo key="logo" />,
            ];
        case TRAVEL_CAR_ROUTE:
            return [
                <TravelTypeSelection key="travelTypeSelection" {...defaultProps} />,
                <TravelRoute key="travelRoute" {...defaultProps} />,
                <TravelMap key="travelMap" {...defaultProps} />,
                <CarRegistrationNumber key="carRegistrationNumber" {...defaultProps} />,
                <TravelDistance key="travelDistance" {...defaultProps} />,
                <TravelDistanceCalculator key="travelDistanceCalculator" {...defaultProps} />,
                <TravelTime key="travelTime" {...defaultProps} />,
                <TravelCosts key="travelCosts" {...defaultProps} />,
                <TravelAllowances key="travelAllowances" {...defaultProps} />,
                <Logo key="logo" />,
            ];
        case TRAVEL_CAR_MAP:
            return [
                <TravelTypeSelection key="travelTypeSelection" {...defaultProps} />,
                <TravelMap key="travelMap" {...defaultProps} />,
                <CarRegistrationNumber key="carRegistrationNumber" {...defaultProps} />,
                <TravelRoute key="travelRoute" {...defaultProps}>
                    <TravelDistance {...defaultProps} />
                </TravelRoute>,
                <TravelDistanceCalculator key="travelDistanceCalculator" {...defaultProps} />,
                <TravelTime key="travelTime" {...defaultProps} />,
                <TravelCosts key="travelCosts" {...defaultProps} />,
                <TravelAllowances key="travelAllowances" {...defaultProps} />,
            ];
        case TRAVEL_OTHER_ROUND:
            return [
                <TravelTypeSelection key="travelTypeSelection" {...defaultProps} />,
                <TravelRound key="travelRound" {...defaultProps} />,
                <TravelDistanceCalculator key="travelDistanceCalculator" {...defaultProps} />,
                <TravelTime key="travelTime" {...defaultProps} />,
                <TravelCosts key="travelCosts" {...defaultProps} />,
                <TravelAllowances key="travelAllowances" {...defaultProps} />,
                <Logo key="logo" />,
            ];
        case TRAVEL_OTHER_ROUTE:
            return [
                <TravelTypeSelection key="travelTypeSelection" {...defaultProps} />,
                <TravelRoute key="travelRoute" {...defaultProps} />,
                <TravelDistanceCalculator key="travelDistanceCalculator" {...defaultProps} />,
                <TravelTime key="travelTime" {...defaultProps} />,
                <TravelCosts key="travelCosts" {...defaultProps} />,
                <TravelAllowances key="travelAllowances" {...defaultProps} />,
                <Logo key="logo" />,
            ];
        default:
            return [];
    }
};

export const getBackButtonContent = (step: number) => {
    switch (step) {
        case START:
            return trans('general.cancel');
        default:
            return (
                <>
                    <Icon icon={['far', 'chevron-left']} /> {trans('menu.back')}
                </>
            );
    }
};

export const getContinueButtonContent = (state: ICostState) => {
    switch (state.step) {
        case RECEIPT:
            if (state.travelCostTemp?.isNew) {
                return trans('form.add');
            }
            return trans('general.save');
        default:
            return trans('general.save');
    }
};

export const vatOptions = (vatList: number[]) => {
    // Given used VATs return options left to be used
    const vats: number[] =
        !vatList || vatList.length === 0 ? COST_VAT_PERCENTAGES : R.difference(COST_VAT_PERCENTAGES, vatList);
    const options: IDropdownOption[] = COST_VAT_PERCENTAGES.map((vat) => ({
        disabled: !vats.includes(vat),
        label: trans('form.percent', { percentage: vat.toString() }),
        value: vat.toString(),
    }));
    return options;
};

export const getReceiptCost = (costId: number, invoice: IInvoice): IReceiptCost | undefined => {
    const cost = invoice.costInvoice?.receiptCosts.find((r: IReceiptCost) => {
        return r.id === costId;
    });
    if (!cost) {
        return;
    }
    return {
        ...cost,
        receiptFiles: cost?.receiptFiles.map((r) => ({
            ...r,
            isNew: false,
            selected: true,
        })),
    };
};

export const getTravel = (travelId: number, invoice: IInvoice): ITravel | undefined => {
    const travel = invoice.costInvoice?.travels.find((t: ITravel) => {
        return t.id === travelId;
    });
    if (!travel) {
        return;
    }
    return {
        ...travel,
        dailyAllowancesFull: travel?.dailyAllowancesFull || {
            quantity: 0,
            salaryType: 'domestic-allowance',
        },
        dailyAllowancesHalf: travel?.dailyAllowancesHalf || { quantity: 0 },
        receiptCosts: travel?.receiptCosts.map((r: IReceiptCost) => ({
            ...r,
            receiptFiles: r.receiptFiles.map((f) => ({
                ...f,
                isNew: false,
                selected: true,
            })),
        })),
        reimbursedKMs: travel?.reimbursedKMs || { quantity: 0 },
        reimbursedMeals: travel?.reimbursedMeals || { quantity: 0 },
        reimbursedTrailerKMs: travel?.reimbursedTrailerKMs || { quantity: 0 },
    };
};

export const isRoundTrip = (route: string[]) => {
    return route.slice(0, 1)[0] === route.slice(-1)[0];
};

export const isTravelByCar = (step: number) => {
    switch (step) {
        case TRAVEL_CAR_ROUND:
        case TRAVEL_CAR_ROUTE:
        case TRAVEL_CAR_MAP:
            return true;
        default:
            return false;
    }
};

export const isRoundTripStep = (step: number) => {
    switch (step) {
        case TRAVEL_CAR_ROUND:
        case TRAVEL_OTHER_ROUND:
            return true;
        default:
            return false;
    }
};

export const getInitialStep = (costId?: number, cost?: IReceiptCost, travel?: ITravel) => {
    if (!costId) {
        return START;
    }
    if (!cost && travel) {
        if (!travel.reimbursedKMs.quantity) {
            if (isRoundTrip(travel.route)) {
                return TRAVEL_OTHER_ROUND;
            }
            return TRAVEL_OTHER_ROUTE;
        }
        if (isRoundTrip(travel.route)) {
            return TRAVEL_CAR_ROUND;
        }
        return TRAVEL_CAR_ROUTE;
    }
    if (cost && !travel) {
        return MATERIAL;
    }
    return START;
};

export const getDestination = (route: string[]) => {
    if (!route || route.length < 2) {
        return '';
    }
    if (isRoundTrip(route)) {
        return route.slice(-2, -1)[0];
    }
    return route.slice(-1)[0];
};

export const formatTravelRoutes = (route: string[]) => {
    if (!route || route.length === 0) {
        return {
            travelRound: { origin: '', destination: '' },
            travelRoute: ['', ''],
        };
    }
    const origin = route.slice(0, 1)[0] || '';
    const destination = getDestination(route);
    return { travelRound: { origin, destination }, travelRoute: route };
};

export const getTravelReceiptById = (id: number, travel: ITravel) => {
    return travel.receiptCosts.find((r: IReceiptCost) => {
        return r.id === id;
    });
};

export const getTravelReceiptByIndex = (i: number, travel: ITravel) => {
    return travel.receiptCosts.find((_r: IReceiptCost, index) => {
        return i === index;
    });
};

export const getReceipt = (currentCost?: IReceiptCost, tempCost?: ITravelCostTemp) => {
    if (tempCost?.cost) {
        return tempCost.cost;
    }
    return currentCost;
};

export const getCostTotal = (amounts?: IAmountWithVat[]) => {
    if (!amounts) {
        return 0;
    }
    return amounts.reduce((total: number, a: IAmountWithVat) => total + a.amount, 0);
};

export const getCostTotalWithoutVat = (amounts?: IAmountWithVat[]) => {
    if (!amounts) {
        return 0;
    }
    return round(
        amounts.reduce(
            (total: number, a: IAmountWithVat) => total + divide(a.amount, divide(100 + a.vatPercent, 100)),
            0,
        ),
    );
};

export const getCostsTotalWithVat = (receiptCosts: IReceiptCost[]) => {
    return receiptCosts.reduce(
        (total: number, r: IReceiptCost) => total + getCostTotal(r.amountsWithVats),
        0,
    );
};

export const getTravelTotal = (t: ITravel) => {
    return (
        t.allowancesTotal +
        t.receiptCosts.reduce((total: number, c: IReceiptCost) => total + getCostTotal(c.amountsWithVats), 0)
    );
};

export const getTravelsTotal = (travels: ITravel[]) => {
    return travels.reduce((total: number, t: ITravel) => total + getTravelTotal(t), 0);
};

const allowanceTotal = (allowance?: IAllowance) => {
    return allowance ? allowance?.quantity * (allowance.unitPrice || 0) : 0;
};

export const getTravelRejectedTotal = (travel: ITravel) => {
    return (
        travel.receiptCosts.reduce((total: number, c: IReceiptCost) => {
            if (c.status === 'rejected') {
                return total + getCostTotal(c.amountsWithVats);
            }
            return total;
        }, 0) +
        (travel.dailyAllowancesFull?.status === 'rejected' ? allowanceTotal(travel.dailyAllowancesFull) : 0) +
        (travel.dailyAllowancesHalf?.status === 'rejected' ? allowanceTotal(travel.dailyAllowancesHalf) : 0) +
        (travel.reimbursedKMs?.status === 'rejected' ? allowanceTotal(travel.reimbursedKMs) : 0) +
        (travel.reimbursedTrailerKMs?.status === 'rejected'
            ? allowanceTotal(travel.reimbursedTrailerKMs)
            : 0) +
        (travel.reimbursedMeals?.status === 'rejected' ? allowanceTotal(travel.reimbursedMeals) : 0)
    );
};

export const getCostInvoiceRejectedTotal = (costInvoice: ICostInvoice) => {
    return (
        costInvoice.receiptCosts.reduce((total: number, c: IReceiptCost) => {
            if (c.status === 'rejected') {
                return total + getCostTotal(c.amountsWithVats);
            }
            return total;
        }, 0) +
        costInvoice.travels.reduce(
            (travelTotal: number, t: ITravel) => travelTotal + getTravelRejectedTotal(t),
            0,
        )
    );
};

export const filterTravelsByStatus = (travels: ITravel[], status: CostStatus) => {
    return travels.filter((travel: ITravel) => {
        return (
            travel.receiptCosts.filter((tr: IReceiptCost) => tr.status === status).length > 0 ||
            travel.reimbursedKMs?.status === status ||
            travel.reimbursedTrailerKMs?.status === status ||
            travel.reimbursedMeals?.status === status ||
            travel.dailyAllowancesHalf?.status === status ||
            travel.dailyAllowancesFull?.status === status
        );
    });
};

export const getTravelsTotalWithoutStatus = (travels: ITravel[], status: CostStatus, taxable?: boolean) => {
    return travels.reduce((total: number, travel: ITravel) => {
        if (taxable !== undefined && ((taxable && !travel.taxable) || (!taxable && travel.taxable))) {
            return total;
        }
        return (
            total +
            travel.receiptCosts.reduce((costTotal: number, cost: IReceiptCost) => {
                return costTotal + (cost.status === status ? 0 : getCostTotal(cost.amountsWithVats));
            }, 0) +
            (travel.dailyAllowancesFull?.status !== status ? allowanceTotal(travel.dailyAllowancesFull) : 0) +
            (travel.dailyAllowancesHalf?.status !== status ? allowanceTotal(travel.dailyAllowancesHalf) : 0) +
            (travel.reimbursedKMs?.status !== status ? allowanceTotal(travel.reimbursedKMs) : 0) +
            (travel.reimbursedTrailerKMs?.status !== status
                ? allowanceTotal(travel.reimbursedTrailerKMs)
                : 0) +
            (travel.reimbursedMeals?.status !== status ? allowanceTotal(travel.reimbursedMeals) : 0)
        );
    }, 0);
};

export const formatTravelDescription = (travel: ITravel) => {
    return `${trans('costs.travel')} ${formatDate(travel.startTime || '')}`;
};

export const getCostInvoiceTotalWithoutVat = (costInvoice: ICostInvoice) => {
    const costs: IReceiptCost[] = costInvoice.receiptCosts.concat(
        ...costInvoice.travels.map((t: ITravel) => t.receiptCosts),
    );
    return costs.reduce(
        (total: number, c: IReceiptCost) => total + getCostTotalWithoutVat(c.amountsWithVats),
        0,
    );
};

export const getCostInvoiceServiceCharge = (invoiceTotal: number) => {
    return round(multiply(multiply(0.01, invoiceTotal), vatFactor(24)));
};

const cleanAllowance = (allowance?: IAllowance): InputAllowance => {
    if (!allowance) {
        return {
            quantity: 0,
        };
    }
    return {
        quantity: allowance.quantity,
        salaryType: allowance?.salaryType,
    };
};

export const costToCostInput = (cost: IReceiptCost): IReceiptCostInput => {
    return {
        amountsWithVats: cost.amountsWithVats || [],
        description: cost.description || '',
        purchaseDate: cost.purchaseDate || '',
        receiptFiles: cost.receiptFiles.filter((r: IFile) => (r as IUiFile).selected).map((r) => r.id),
    };
};

export const roundToRoute = (roundTrip: IRound): string[] => {
    return [roundTrip.origin, roundTrip.destination, roundTrip.origin];
};

const cleanTravelCar = (travel: ITravel) => {
    return {
        dailyAllowancesFull: cleanAllowance(travel.dailyAllowancesFull),
        dailyAllowancesHalf: cleanAllowance(travel.dailyAllowancesHalf),
        endTime: travel.endTime || '',
        receiptCosts: travel.receiptCosts.map((r: IReceiptCost) => {
            return costToCostInput(r);
        }),
        reimbursedKMs: cleanAllowance(travel.reimbursedKMs),
        reimbursedMeals: cleanAllowance(travel.reimbursedMeals),
        reimbursedTrailerKMs: cleanAllowance(travel.reimbursedTrailerKMs),
        startTime: travel.startTime || '',
        carNumber: travel.carNumber,
    };
};

const cleanTravelOther = (travel: ITravel) => {
    return {
        dailyAllowancesFull: cleanAllowance(travel.dailyAllowancesFull),
        dailyAllowancesHalf: cleanAllowance(travel.dailyAllowancesHalf),
        endTime: travel.endTime || '',
        receiptCosts: travel.receiptCosts.map((r: IReceiptCost) => {
            return costToCostInput(r);
        }),
        reimbursedKMs: { quantity: 0 },
        reimbursedMeals: cleanAllowance(travel.reimbursedMeals),
        reimbursedTrailerKMs: { quantity: 0 },
        startTime: travel.startTime || '',
    };
};

export const currentTravelToTravelInput = (state: ICostState): ITravelInput | undefined => {
    if (!state.currentTravel) {
        return;
    }
    const travelCar = cleanTravelCar(state.currentTravel);
    const travelOther = cleanTravelOther(state.currentTravel);

    const totalKMs = { totalKMs: state.currentTravel?.totalKMs };

    switch (state.step) {
        case TRAVEL_CAR_ROUND:
            return {
                ...travelCar,
                route: roundToRoute(state.travelRound),
                ...totalKMs,
            };
        case TRAVEL_OTHER_ROUND:
            return {
                ...travelOther,
                route: roundToRoute(state.travelRound),
                ...totalKMs,
            };
        case TRAVEL_CAR_ROUTE:
            return { ...travelCar, route: state.travelRoute, ...totalKMs };
        case TRAVEL_CAR_MAP:
            return { ...travelCar, route: state.travelRoute, ...totalKMs };
        case TRAVEL_OTHER_ROUTE:
            return { ...travelOther, route: state.travelRoute, ...totalKMs };
        default:
            return;
    }
};

export const travelToTravelInput = (travel: ITravel): ITravelInput => {
    return {
        dailyAllowancesFull: cleanAllowance(travel.dailyAllowancesFull),
        dailyAllowancesHalf: cleanAllowance(travel.dailyAllowancesHalf),
        endTime: travel.endTime || '',
        receiptCosts: travel.receiptCosts.map((r: IReceiptCost) => {
            return costToCostInput(r);
        }),
        reimbursedKMs: cleanAllowance(travel.reimbursedKMs),
        reimbursedMeals: cleanAllowance(travel.reimbursedMeals),
        reimbursedTrailerKMs: cleanAllowance(travel.reimbursedTrailerKMs),
        route: travel.route,
        startTime: travel.startTime || '',
        totalKMs: travel.totalKMs,
        carNumber: travel.carNumber || '',
    };
};

const travelTimeReferences = (startTime?: string, endTime?: string) => {
    return {
        maxEnd: getMaxTravelDate(new Date(), dateOrDefault(startTime)),
        maxStart: dateOrDefault(endTime),
        minEnd: dateOrDefault(startTime, getMinTravelDate(new Date())),
        minStart: getMinTravelDate(new Date()),
    };
};

export const validateTravel = (state: ICostState) => {
    // travel can be inserted in 5 different views and each of them
    // need to track their validity on their own
    const travelTimeRefs = travelTimeReferences(state.currentTravel?.startTime, state.currentTravel?.endTime);
    const travelCarRoundErrors = formatValidationResult(
        validators.travelCar.validate({
            ...state.currentTravel,
            route: roundToRoute(state.travelRound),
            ...travelTimeRefs,
        }),
    );
    const travelCarRouteErrors = formatValidationResult(
        validators.travelCar.validate({
            ...state.currentTravel,
            route: state.travelRoute,
            ...travelTimeRefs,
        }),
    );
    const travelOtherRoundErrors = formatValidationResult(
        validators.travelOther.validate({
            ...state.currentTravel,
            route: roundToRoute(state.travelRound),
            ...travelTimeRefs,
        }),
    );
    const travelOtherRouteErrors = formatValidationResult(
        validators.travelOther.validate({
            ...state.currentTravel,
            route: state.travelRoute,
            ...travelTimeRefs,
        }),
    );
    return {
        [TRAVEL_CAR_ROUND]: travelCarRoundErrors,
        [TRAVEL_CAR_ROUTE]: travelCarRouteErrors,
        [TRAVEL_CAR_MAP]: travelCarRouteErrors, // map is basically the same as route
        [TRAVEL_OTHER_ROUND]: travelOtherRoundErrors,
        [TRAVEL_OTHER_ROUTE]: travelOtherRouteErrors,
    };
};

export const getCountry = (address: string) => {
    return Object.values(countriesEn)
        .concat(Object.values(countriesFi))
        .find((c: string) => address.includes(c));
};

export const isDomesticTrip = (route: string[]) => {
    // Simple heuristics to try to detect countries in the addresses inserted in Extra (no validation)
    const destination = getDestination(route);
    if (destination.includes(', Finland')) {
        // all addresses inserted in Ankkanet
        return true;
    }
    if (destination.includes(', Suomi')) {
        return true;
    }
    if (getCountry(destination)) {
        return false;
    }
    return true;
};

export const getTravelHoursCount = (travel?: ITravel): number => {
    if (!travel || !travel.startTime || !travel.endTime) {
        return 0;
    }
    return hoursBetween(travel.startTime, travel.endTime);
};

export const filterNewAttachments = (allFiles: IFile[], oldFiles: IUiFile[]): IUiFile[] => {
    // return the files that are in allFiles but not in oldFiles
    return allFiles
        .filter((a: IFile) => !oldFiles.find((r) => r.id === a.id))
        .map((a: IFile) => ({
            ...a,
            isNew: true,
            selected: false,
        }));
};

export const filterRemainedFiles = (newFiles: IFile[], oldFiles: IUiFile[]): IUiFile[] => {
    // return the old files that either are !isNew or exist in newFiles
    return oldFiles.filter((f: IUiFile) => !f.isNew || newFiles.find((a: IFile) => a.id === f.id));
};

export const getMinTravelDate = (today: Date) => {
    const firstOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
    return new Date(firstOfLastYear.setHours(0, 0, 0, 0));
};

export const getMaxTravelDate = (today: Date, startDate?: Date) => {
    const inThreeMonths = new Date(today.getFullYear(), today.getMonth() + 3, today.getDate());
    const latestDate = new Date(inThreeMonths.setHours(23, 59, 59, 59));

    if (startDate) {
        const yearLater = new Date(
            new Date(new Date(startDate).setFullYear(startDate.getFullYear() + 1)).setHours(23, 59, 59, 59),
        );
        if (xNotAfterYInDays(yearLater, latestDate)) {
            return yearLater;
        }
    }
    return latestDate;
};

export const countCostStatuses = (costInvoice: ICostInvoice, status: CostStatus) => {
    const costs = costInvoice.receiptCosts.filter((r: IReceiptCost) => r.status === status);
    const travels = filterTravelsByStatus(costInvoice.travels, status);
    return costs.length + travels.length;
};

export const getCostStatus = (
    costInvoice: ICostInvoice,
): {
    color: string;
    text: string;
} | null => {
    if (costInvoice.status === 'rejected') {
        //  hylätty
        return {
            color: COLOR_IMPORTANT,
            text: trans('costs.statuses.rejected'),
        };
    }
    if (costInvoice.status === 'turned_back') {
        // palautettu
        return {
            color: COLOR_STATUS_WAITING,
            text: trans('costs.statuses.turned_back'),
        };
    }
    if (costInvoice.status === 'unaccepted' && costInvoice.preAccepted) {
        return {
            color: COLOR_STATUS_WAITING,
            text: trans('costs.statuses.waiting-for-payment'),
        };
    }
    if (costInvoice.status === 'unaccepted') {
        // odottaa käsittelyä
        return {
            color: COLOR_STATUS_WAITING,
            text: trans('costs.statuses.unaccepted'),
        };
    }
    if (costInvoice.status === 'incomplete') {
        // keskeneräinen
        return {
            color: COLOR_GREYJOY,
            text: trans('costs.statuses.incomplete'),
        };
    }
    if (countCostStatuses(costInvoice, 'rejected') > 0) {
        // osittain hyväksytty
        return {
            color: COLOR_STATUS_DONE,
            text: trans('costs.statuses.partly-accepted'),
        };
    }
    if (countCostStatuses(costInvoice, 'accepted') > 0) {
        // kaikki hyväksytty
        return {
            color: COLOR_STATUS_DONE,
            text: trans('costs.statuses.all-accepted'),
        };
    }
    return null;
};

export const isSupplyReceipt = (receipt: IReceiptCost) => {
    // Use terms in translations: costs.costReceiptType.supply
    return receipt.description === 'Tarvike' || receipt.description === 'Supply';
};

export const getTravelReceiptType = (receipt: IReceiptCost) => {
    // Use terms in translations: costs.travelReceiptType.accommodation & costs.travelReceiptType.ticket
    switch (receipt.description) {
        case 'Majoitus':
        case 'Accommodation':
            return 'accommodation';
        case 'Matkalippu':
        case 'Ticket':
            return 'ticket';
        default:
            return undefined;
    }
};

export const dateInBetweenDates = (date: IDatePair, rangeDate: IDatePair) => {
    // Ignore time if datetimes given
    const range = rangeMoment.range(
        moment(rangeDate.startDate).startOf('day'),
        moment(rangeDate.endDate).endOf('day'),
    );
    return (
        (!date.startDate || range.contains(moment(formatDateISO(date.startDate)))) &&
        (!date.endDate || range.contains(moment(formatDateISO(date.endDate))))
    );
};

export const datesContainDate = (date: IDatePair, dates: IDatePair[]): boolean | undefined => {
    if ((!date.startDate && !date.endDate) || dates.length === 0) {
        return undefined;
    }
    return dates.find((d: IDatePair) => dateInBetweenDates(date, d)) !== undefined;
};

export const getTravelCopy = (travel: ITravelInput): ITravelInput => {
    const { startTime, endTime } = travel;

    return {
        ...travel,
        endTime: addDayToDatetime(endTime) || '',
        receiptCosts: [],
        startTime: addDayToDatetime(startTime) || '',
    };
};

export const canCostInvoiceBeEditedEvenInvoiceIsPaid = (invoice: IInvoice): boolean => {
    if (R.isNil(invoice.salaryPaymentStatus)) {
        return true;
    }
    const canEditEvenPaid =
        (invoice.salaryPaymentStatus === 'unaccepted' || invoice.salaryPaymentStatus === 'countable') &&
        invoice.costInvoice?.status !== 'rejected';

    return canEditEvenPaid;
};

export const checkContract = (data: IContract[]): boolean => {
    const now = moment();

    if (data) {
        const validContracts = data.filter((contract) => {
            const startDate = moment(contract.startDate || moment());
            const endDate = moment(contract.endDate || moment());

            return now.isBetween(startDate, endDate, undefined, '[]');
        });

        return validContracts.length > 0;
    }

    return false;
};
