import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { COLOR_LILA } from 'styles/variables';
import { useChangeUserLanguageMutation } from 'queries/useUserQuery';
import type { LabelValueType } from 'types/common.type';
import type { UiLanguage } from '../../../shared/src/types/user';

const LanguageSection = styled.div`
    button {
        font-weight: bold;
        padding: 0 8px;

        &:hover,
        &.selected {
            text-decoration: underline;
        }

        &:focus {
            border-bottom: 1px solid ${COLOR_LILA};
        }
    }
    button:not(:first-child) {
        border-left: 2px solid ${COLOR_LILA};
    }
    button {
        color: ${COLOR_LILA};
        font-size: 16px;
    }
`;

const LanguageOptions = () => {
    const { t, i18n } = useTranslation();
    const changeUserLanguage = useChangeUserLanguageMutation();

    const options: LabelValueType<UiLanguage>[] = [
        { label: 'FI', value: 'fi' },
        { label: 'EN', value: 'en' },
    ];

    return (
        <LanguageSection>
            {options.map((i) => (
                <button
                    key={i.value}
                    type="button"
                    className={i18n.language === i.value ? 'selected' : ''}
                    aria-label={t(`menu.lang-change-${i.value}`)}
                    title={t(`menu.lang-change-${i.value}`)}
                    onClick={() => changeUserLanguage(i.value)}
                >
                    {i.label}
                </button>
            ))}
        </LanguageSection>
    );
};

export default LanguageOptions;
