import type { LabelValueType } from 'types/common.type';
import { cn } from 'utils';
import { NavItemBadge } from 'containers/nav/drawer.styles';

interface INestedPageNavigationTabs<T extends string> {
    links: LabelValueType<T>[];
    activeValue: T;
    onChange?: (value: T) => void;
}

const NestedPageNavigationTabs = <T extends string>(props: INestedPageNavigationTabs<T>) => {
    return (
        <div
            className="border-b flex flex-col md:flex-row justify-between gap-8"
            style={{ borderColor: '#E4E4E7' }}
        >
            <div className="flex gap-7 items-start">
                {props.links.map((i) => (
                    <div
                        key={i.value}
                        className={cn(
                            'tg-body-medium cursor-pointer pb-4 relative',
                            props.activeValue === i.value &&
                                "text-violet-800 after:content-[''] after:block after:absolute after:bottom-0 after:w-full after:h-[2px] after:-mb-px after:bg-violet-700 after:rounded-t-full tg-body-bold",
                        )}
                        onClick={() => props.onChange?.(i.value)}
                    >
                        {i.label}
                        {i.info?.badge ? <NavItemBadge>{i.info.badge}</NavItemBadge> : null}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NestedPageNavigationTabs;
