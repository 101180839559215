export const BannerStoreKeyName = 'EezyDataBanner';
export const CURRENT_BANNER_VERSION = 0; // Increment this when banner content changes and we should make the new banner visible to all users

export const EEZY_DATA_BANNER_CONFIG = {
    visibilityPeriod: {
        from: '2024-10-15',
        to: '2024-11-17',
    },
    image: '/imgs/banners/campaign_banner_lg.png',
    buttons: {
        en: [
            {
                type: 'primary',
                value: 'https://example.com/feature-details',
                translationKey: 'banner.release-features.buttons.primary',
            },
            {
                type: 'default',
                value: 'https://kevytyrittajat-asiakaskirje.eezy.fi/a/s/75564342-083285f0d7d7d3380b411f5f7aece92a/2026285',
                translationKey: 'banner.release-features.buttons.secondary',
            },
        ],
        fi: [
            {
                type: 'primary',
                value: 'https://example.com/feature-details-fi',
                translationKey: 'banner.release-features.buttons.primary',
            },
            {
                type: 'default',
                value: 'https://kevytyrittajat-asiakaskirje.eezy.fi/a/s/75564342-45f142fb51ab03aa3ba09e8710184dea/1981259',
                translationKey: 'banner.release-features.buttons.secondary',
            },
        ],
    },
    translationKeys: {
        title: 'banner.release-features.title',
        description: 'banner.release-features.description',
        shortDescription: 'banner.release-features.short-description',
    },
} as const;
