import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AdditionalCostIcon from '../../assets/images/additional-expenses.svg';
import { InlineButtonLink } from '../../components/Buttons';
import { Icon } from '../../components/Icon';
import { BodyP, LabelStrong, P } from '../../components/textElements';
import { Tools, UtilsHeader } from '../../components/TogglingTools';
import { BORDER_RADIUS, COLOR_BLACKWATER, COLOR_BLUM, COLOR_MEDIUM_BG } from '../../styles/variables';
import { trans } from '../../utils';
import { offsetFromTop } from '../../utils/ui';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    background-color: ${COLOR_MEDIUM_BG};
    border-radius: ${BORDER_RADIUS};
    margin: 0 12px;
    padding: 0 30px 30px;
    position: relative;
`;

const CostIcon = styled.div`
    background: url(${AdditionalCostIcon});
    float: right;
    margin-left: 30px;
    width: 30px;
    min-width: 30px;
    height: 30px;
`;

const CostManualBodyLinks = () => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <BodyP style={{ marginTop: 16 }}>
                <a
                    href={
                        i18n.language === 'en'
                            ? 'https://kevytyrittajat.eezy.fi/en/when-am-i-able-to-get-expenses-reimbursed/'
                            : 'https://kevytyrittajat.eezy.fi/milloin-voin-saada-kulukorvauksia/'
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('costs.modal-info3')}
                    <Icon
                        className="small"
                        color={COLOR_BLUM}
                        icon={['far', 'arrow-up-right-from-square']}
                        style={{ marginLeft: 10 }}
                    />
                </a>
            </BodyP>

            <BodyP style={{ marginTop: 10 }}>
                <a
                    href={
                        i18n.language === 'en'
                            ? 'https://kevytyrittajat.eezy.fi/en/this-is-how-you-apply-for-expense-reimbursements/'
                            : 'https://kevytyrittajat.eezy.fi/nain-haet-kulukorvauksia/'
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('costs.modal-info4')}
                    <Icon
                        className="small"
                        color={COLOR_BLUM}
                        icon={['far', 'arrow-up-right-from-square']}
                        style={{ marginLeft: 10 }}
                    />
                </a>
            </BodyP>

            <BodyP style={{ marginTop: 10 }}>
                <a
                    href={
                        i18n.language === 'en'
                            ? 'https://kevytyrittajat.eezy.fi/en/examples-of-reimbursable-expenses/'
                            : 'https://kevytyrittajat.eezy.fi/esimerkkeja-korvattavista-kuluista/'
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('costs.modal-info5')}
                    <Icon
                        className="small"
                        color={COLOR_BLUM}
                        icon={['far', 'arrow-up-right-from-square']}
                        style={{ marginLeft: 10 }}
                    />
                </a>
            </BodyP>
        </>
    );
};

export const CostManualDesktop = () => {
    const { t } = useTranslation();
    const [topOffset, setTopOffset] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTopOffset(Math.max(offsetFromTop('cost-document') - offsetFromTop('cost-manual'), 0));
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    });

    return (
        <Wrapper
            style={{
                top: topOffset,
            }}
        >
            <Tools
                header={
                    <UtilsHeader>
                        <LabelStrong as="h4" color={COLOR_BLACKWATER}>
                            {t('costs.section.title')}
                        </LabelStrong>
                        <CostIcon />
                    </UtilsHeader>
                }
            >
                <BodyP color={COLOR_BLACKWATER} style={{ marginTop: 10 }}>
                    {t('costs.section.info-1')}
                </BodyP>
                <BodyP color={COLOR_BLACKWATER} style={{ marginTop: 10 }}>
                    <b>{t('costs.section.info-2')}</b>
                    {t('costs.section.info-3')}
                </BodyP>

                <CostManualBodyLinks />
            </Tools>
        </Wrapper>
    );
};

export const CostManualMobile = () => {
    const { t } = useTranslation();
    const [showMore, setShowMore] = React.useState(false);
    return (
        <div style={{ margin: 40 }}>
            <P color={COLOR_BLUM}>
                {t('costs.section.mobile-1')}
                <b>{t('costs.section.mobile-2')}</b>
                {t('costs.section.mobile-3')}
                {showMore ? (
                    t('costs.section.mobile-4')
                ) : (
                    <>
                        ...{' '}
                        <InlineButtonLink onClick={() => setShowMore(true)}>
                            {t('costs.section.show-more')}
                        </InlineButtonLink>
                    </>
                )}
            </P>
            {showMore && <P color={COLOR_BLUM}>{trans('costs.section.mobile-5')}</P>}
            {showMore && <CostManualBodyLinks />}
        </div>
    );
};
