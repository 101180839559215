import { ValidationMessageBlock } from '../../components/ValidationMessageBlock';
import { COLOR_BLACK, COLOR_WARINING_BACKGROUND } from '../../styles/variables';
import { EezyButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';

interface IProps {
    onClose?: () => void;
    handleAddCostInvoice?: () => void;
}

const ExpenseReimbursementWarning = (props: IProps) => {
    const { t } = useTranslation();
    return (
        <ValidationMessageBlock
            iconColor={COLOR_BLACK}
            type={'other'}
            style={{
                backgroundColor: COLOR_WARINING_BACKGROUND,
                borderRadius: 8,
                marginTop: 24,
                padding: '16px 20px',
                width: '100%',
            }}
            title={t('costs.warning.expense-reimbursement-title')}
            onClose={props.onClose}
        >
            <div className="flex flex-col gap-4 w-full">
                <ul
                    className="flex flex-col gap-3 list-disc pl-4"
                    style={{ fontSize: '13px', lineHeight: '16px' }}
                >
                    <li>
                        <div className="-ml-1" style={{ fontSize: '13px', lineHeight: '16px' }}>
                            <span style={{ fontSize: '13px', lineHeight: '16px' }}>
                                {t('costs.warning.travel-and-supplies')}:{' '}
                            </span>
                            <span style={{ fontSize: '13px', lineHeight: '16px' }}>
                                {t('costs.warning.tax-free-explanation')} {t('costs.warning.processing-fee')}
                            </span>
                        </div>
                    </li>

                    <li>
                        <div className="-ml-1" style={{ fontSize: '13px', lineHeight: '16px' }}>
                            <span style={{ fontSize: '13px', lineHeight: '16px' }}>
                                {t('costs.warning.supplies-only')}:{' '}
                            </span>
                            <span style={{ fontSize: '13px', lineHeight: '16px' }}>
                                {t('costs.warning.tax-authority-requirement')}
                            </span>
                        </div>
                    </li>

                    <li>
                        <div className="-ml-1" style={{ fontSize: '13px', lineHeight: '16px' }}>
                            <span style={{ fontSize: '13px', lineHeight: '16px' }}>
                                {t('costs.warning.alternative-tax-deduction')}
                            </span>
                        </div>
                    </li>
                </ul>

                {props.handleAddCostInvoice && (
                    <div className="self-end">
                        <EezyButton color="black" onClick={props.handleAddCostInvoice}>
                            <span style={{ fontSize: '13px', lineHeight: '16px' }}>
                                {t('costs.warning.apply-for-reimbursements')}
                            </span>
                        </EezyButton>
                    </div>
                )}
            </div>
        </ValidationMessageBlock>
    );
};

export default ExpenseReimbursementWarning;
