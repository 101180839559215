// APP
export const SHOW_ALERT = 'APP/SHOW_ALERT';
export const HIDE_ALERT = 'APP/HIDE_ALERT';
export const SHOW_CONFIRMATION = 'APP/SHOW_CONFIRMATION';
export const HIDE_CONFIRMATION = 'APP/HIDE_CONFIRMATION';
export const SHOW_INTRO = 'APP/SHOW_INTRO';

// AUTH
export const AUTH_FAILURE = 'AUTH/FAILURE';
export const AUTH_FAILURE_HIDE = 'AUTH/FAILURE_HIDE';
export const SHOW_MODALS = 'AUTH/SHOW_MODALS';
export const HIDE_MODAL = 'AUTH/HIDE_MODAL';

export const AUTH_ACTION_FAILURE = 'AUTH/ACTION_FAILURE';
export const AUTH_ACTION_BEGIN = 'AUTH/ACTION_BEGIN';

export const RECENTLY_IDENTIFIED = 'AUTH/RECENTLY_IDENTIFIED';

export const VERIFICATION_CODE_REQUIRED = 'AUTH/VERIFICATION_CODE_REQUIRED';

// FILE
export const FILE_UPLOAD_BEGIN = 'FILE/UPLOAD_BEGIN';
export const FILE_UPLOAD_FAILURE = 'FILE/UPLOAD_FAILURE';
export const FILE_UPLOAD_FAILURE_HIDE = 'FILE/HIDE_ERROR';
export const ADD_COST_FILE = 'FILE/ADD_COST_FILE';
export const DELETE_COST_FILE_ID = 'FILE/DELETE_COST_FILE_ID';
export const ADD_TAX_CARD = 'FILE/ADD_TAX_CARD';
export const ADD_INVOICE_ATTACHMENT = 'FILE/ADD_INVOICE_ATTACHMENT';
export const ADD_INVOICE_LOGO = 'FILE/ADD_INVOICE_LOGO';
export const DELETE_INVOICE_ATTACHMENT = 'FILE/DELETE_INVOICE_ATTACHMENT';
export const ADD_MESSAGE_ATTACHMENT = 'FILE/ADD_MESSAGE_ATTACHMENT';
export const DELETE_MESSAGE_ATTACHMENT = 'FILE/DELETE_MESSAGE_ATTACHMENT';

// INVOICE
export const INVOICE_SEARCH_QUERY = 'INVOICE_SEARCH_QUERY';
export const INVOICE_SPREAD_STATE = 'INVOICE_SPREAD_STATE';
export const INVOICE_SPREAD_STATE_CLIENT_SET = 'INVOICE_SPREAD_STATE_CLIENT';
export const INVOICE_SET_TRAVEL_WARNING_HIDDEN = 'INVOICE_SET_TRAVEL_WARNING_HIDDEN';
export const INVOICE_SET_EXPENSE_REIMBURSEMENT_WARNING_HIDDEN =
    'INVOICE_SET_EXPENSE_REIMBURSEMENT_WARNING_HIDDEN';

// LOGIN
export const LOGIN_SUCCESS = 'LOGIN/SUCCESS';
export const LOGIN_SHOW = 'LOGIN/SHOW';

// Salary
export const SALARY_SEARCH_QUERY = 'SALARY_SEARCH_QUERY';

// PASSWORD REQUEST
export const PASSWORD_REQUESTED_FAILURE = 'PWD_REQUEST/FAILURE';
export const PASSWORD_REQUESTED = 'PWD_REQUEST/SUCCESS';

// COWORKER
export const SELECTED_COWORKER = 'SELECTED_COWORKER';
export const DELETE_SELECTED_COWORKER = 'DELETE_SELECTED_COWORKER';

// EezyPay
export const SALES_PAGE_SEARCH_QUERY = 'SALES_PAGE_SEARCH_QUERY';
export const TRANSACTIONS_SEARCH_QUERY = 'TRANSACTIONS_SEARCH_QUERY';
export const SUBSCRIPTION_END_DATE = 'SUBSCRIPTION_END_DATE';
export const EEZYPAY_USER_DATA_LOADING = 'EEZYPAY_USER_DATA_LOADING';
export const ACCEPT_EEZYPAY_TERMS = 'ACCEPT_EEZYPAY_TERMS';
export const EEZYPAY_USER_DATA_LOADING_COMPLETE = 'EEZYPAY_USER_DATA_LOADING_COMPLETE';
export const EEZYPAY_USER_DATA_LOADING_ERROR = 'EEZYPAY_USER_DATA_LOADING_ERROR';
