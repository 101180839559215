import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { BannerButton } from '../types';
import { getBannerStoredState, setBannerCloseTimes, toggleBannerVisible } from '../utils';
import { BannerStoreKeyName, CURRENT_BANNER_VERSION, EEZY_DATA_BANNER_CONFIG } from './constants';
import MiniCampaignBanner from '../MiniCampaignBanner';

const EezyDataBanner = () => {
    const { t, i18n } = useTranslation(); // Add i18n here
    const [isTempClose, setTempClose] = useState(false);
    const { visibilityPeriod, image, buttons, translationKeys } = EEZY_DATA_BANNER_CONFIG;

    const title = t(translationKeys.title);
    const description = t(translationKeys.description);
    const shortDescription = t(translationKeys.shortDescription);

    const currentLanguage = i18n.language as 'en' | 'fi';
    const languageButtons = buttons[currentLanguage] || buttons.fi;

    const translatedButtons: BannerButton[] = languageButtons.map((button) => ({
        type: button.type,
        value: button.value,
        label: t(button.translationKey),
    }));

    if (isTempClose) {
        return null;
    }

    const inPeriod = moment().isBetween(visibilityPeriod.from, visibilityPeriod.to, 'day', '[]');

    if (!inPeriod) {
        return null;
    }

    const stored = getBannerStoredState(BannerStoreKeyName, CURRENT_BANNER_VERSION);

    if (!stored.visible || stored.closeTimes > 0) {
        return null;
    }

    const onClose = () => {
        setTempClose(true);
        setBannerCloseTimes(BannerStoreKeyName, stored.closeTimes + 1, CURRENT_BANNER_VERSION);
        toggleBannerVisible(BannerStoreKeyName, false, CURRENT_BANNER_VERSION);
    };

    const handleButtonClick = (item: BannerButton) => {
        if (item.value.startsWith('http')) {
            window.open(item.value, '_blank');
        } else if (item.value === 'open_modal') {
            // Handle modal opening logic here
            // Implement modal opening logic
        } else {
            // Handle internal routes
            // You might want to use react-router's navigate here
            // Implement navigation logic
        }
    };

    return (
        <MiniCampaignBanner
            image={image}
            title={title}
            description={description}
            shortDescription={shortDescription}
            buttons={translatedButtons}
            onClose={onClose}
            onClickButton={handleButtonClick}
            onClickReadMore={() => {
                const readMoreButton = translatedButtons.find((b) => b.type === 'default');
                if (readMoreButton) {
                    handleButtonClick(readMoreButton);
                } else if (translatedButtons.length > 0) {
                    handleButtonClick(translatedButtons[1]);
                }
            }}
        />
    );
};

export default EezyDataBanner;
