import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { SCREEN_M } from 'styles/variables';
import Drawer from 'containers/nav/Drawer';
import FrontPage from 'containers/dashboard/FrontPage';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import DashboardQuickActions from 'containers/dashboard/DashboardQuickActions';
import EezyDataBanner from 'components/banners/EezyDataBanner';
import EezyFeaturedBanner from 'components/banners/EezyFeaturedBanner';

const EezyBanner = () => {
    return (
        <>
            <EezyDataBanner />
            <EezyFeaturedBanner />
        </>
    );
};

const InvoiceWrapper: FC = () => {
    const { width } = useWindowDimensions();

    return (
        <Drawer banner={<EezyBanner />}>
            {width < SCREEN_M ? (
                <div>
                    <DashboardQuickActions style={{ marginBottom: '20px' }} />
                    <Outlet />
                </div>
            ) : (
                <FrontPage>
                    <Outlet />
                </FrontPage>
            )}
        </Drawer>
    );
};

export default InvoiceWrapper;
