import { Icon } from 'components/Icon';
import { EezyButton } from 'components/Buttons';
import type { BannerButton } from '../types';
import styled from 'styled-components';

interface IMiniCampaignBanner {
    image: string;
    title: string;
    shortTitle?: string;
    description: string;
    shortDescription?: string;
    buttons: BannerButton[];
    onClose?: () => void;
    onClickButton?: (item: BannerButton) => void;
    onClickReadMore?: () => void;
}

const TitleWrapper = styled.div`
    @media (max-width: ${1024 - 1}px) {
        * {
            color: #fff !important;
        }
    }
`;

const MiniCampaignBanner = (props: IMiniCampaignBanner) => {
    const readMoreButton = props.buttons.find((button) => button.type === 'default');

    return (
        <div className="pb-2 bg-violet-100 lg:bg-white lg:pb-0">
            <div className="flex items-center justify-between pr-5 lg:pr-10 lg:mx-auto bg-green-500 lg:bg-white h-16 lg:h-108 lg:max-w-lg text-white lg:text-txt-description">
                <div className="flex items-center h-full flex-grow">
                    <TitleWrapper
                        dangerouslySetInnerHTML={{ __html: props.shortTitle || props.title }}
                        className="hidden lg:block ml-9 text-2xl font-semibold no-underline text-gray-800"
                    />
                    <TitleWrapper
                        dangerouslySetInnerHTML={{ __html: props.shortTitle || props.title }}
                        className="lg:hidden ml-6 text-base font-semibold no-underline text-inherit cursor-pointer"
                        onClick={props.onClickReadMore}
                    />
                    <div
                        dangerouslySetInnerHTML={{ __html: props.shortDescription || props.description }}
                        className="hidden lg:block ml-6 lg:ml-9 text-sm text-inherit mr-8"
                    />
                    <div
                        className="flex lg:hidden items-center ml-3 text-inherit cursor-pointer"
                        onClick={props.onClickReadMore}
                    >
                        <button type="button" className="inline-flex">
                            <Icon icon={['far', 'arrow-right']} color="inherit" />
                        </button>
                    </div>
                </div>
                <div className="flex items-stretch justify-stretch gap-10">
                    <div className="hidden lg:flex flex-row-reverse gap-5">
                        {readMoreButton && (
                            <EezyButton
                                key={readMoreButton.value}
                                className="v2-btn"
                                dark={false}
                                color="purple"
                                onClick={props.onClickReadMore}
                            >
                                {readMoreButton.label}
                            </EezyButton>
                        )}
                    </div>
                    <div className="hidden lg:block w-px bg-gray-300" />
                    <div className="flex items-center">
                        <button type="button" className="inline-flex" onClick={props.onClose}>
                            <Icon icon={['far', 'xmark']} color="inherit" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

MiniCampaignBanner.displayName = 'MiniCampaignBanner';

export default MiniCampaignBanner;
