import { useNavigate } from 'react-router-dom';
import { Icon } from 'components/Icon';
import type { CampaignButton } from '../constants';
import { EezyButton } from 'components/Buttons';
import styled from 'styled-components';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { BannerModalBulletPoint } from '../types';
import { FEATURES_BANNER_MODAL_BULLET_ICONS, FEATURES_BANNER_MODAL_UPCOMING_BULLET_ICONS } from '../utils';
import {
    COLOR_EEZY_GREEN_50,
    COLOR_EEZY_GREEN_700,
    COLOR_EEZY_VIOLOET_50,
    COLOR_EEZY_VIOLOET_950,
} from 'styles/variables';
import { useState } from 'react';
import { randomSingleId } from 'utils';

interface IEezyDataBannerModal {
    onClose: () => void;
}

type StyledModalProp = {};
const StyledModal = styled(Dialog)<StyledModalProp>`
    & > div > div {
        overflow: hidden;
        border-radius: 16px;
        padding: 40px 0;
        max-width: 1063px;
    }

    .banner-bullet-point {
        &:nth-child(1) {
            background: ${COLOR_EEZY_GREEN_50};
            color: ${COLOR_EEZY_GREEN_700};
        }
        &:nth-child(2) {
            background: ${COLOR_EEZY_VIOLOET_50};
            color: ${COLOR_EEZY_VIOLOET_950};
        }
    }
`;
const EezyDataBannerModal = (props: IEezyDataBannerModal) => {
    const [isOpen, setOpen] = useState(true);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const bulletPoints = (
        t('banner.release-features.modal-content.bullet-points', {
            returnObjects: true,
        }) as BannerModalBulletPoint[]
    ).map((i, index) => ({
        ...i,
        id: randomSingleId(),
        icon: i.icon || ['far', FEATURES_BANNER_MODAL_BULLET_ICONS[index]],
    }));

    const upcomingBulletPoints = (
        t('banner.release-features.modal-content.upcoming-bullet-points', {
            returnObjects: true,
        }) as BannerModalBulletPoint[]
    ).map((i, index) => ({
        ...i,
        id: randomSingleId(),
        icon:
            i.icon ||
            (FEATURES_BANNER_MODAL_UPCOMING_BULLET_ICONS[index].startsWith('/')
                ? FEATURES_BANNER_MODAL_UPCOMING_BULLET_ICONS[index]
                : ['far', FEATURES_BANNER_MODAL_UPCOMING_BULLET_ICONS[index]]),
    }));

    const buttons: CampaignButton[] = [
        ...(t('banner.release-features.modal-content.buttons', { returnObjects: true }) as CampaignButton[]),
        { label: 'banner.release-features.modal-content.close', value: 'close', type: 'default' },
    ];
    for (const button of buttons) {
        button.label = t(button.label);
    }

    const onClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.onClose();
        }, 300);
    };

    const onClickButton = (item: CampaignButton) => {
        if (item.value?.startsWith('/')) {
            navigate(item.value);
        } else {
            console.warn('[Banner]', item.value);
        }
        onClose();
    };

    return (
        <StyledModal
            id="modal-features-banner"
            open={isOpen}
            onClose={onClose}
            maxWidth={false}
            title={t('banner.release-features.modal-content.title') || ''}
        >
            <div className="modal-content max-h-full flex flex-col gap-14 px-10 overflow-auto">
                <div>
                    <div className="flex items-center justify-between mb-5">
                        <h2
                            className="text-2xl font-semibold"
                            dangerouslySetInnerHTML={{
                                __html: t('banner.release-features.modal-content.title'),
                            }}
                        />
                        <button
                            type="button"
                            className="h-11 w-11 flex justify-center items-center"
                            onClick={onClose}
                        >
                            <Icon icon={['far', 'xmark']} />
                        </button>
                    </div>
                    <p className="text-sm">{t('banner.release-features.modal-content.description')}</p>
                </div>
                <div className="grid lg:grid-cols-2 gap-7">
                    {bulletPoints.map((i) => (
                        <div
                            key={i.id}
                            className="banner-bullet-point flex flex-col items-start p-7 rounded-eezy"
                        >
                            <Icon icon={i.icon as any} className="xl mb-5" color="inherit" />
                            <h4 className="mb-4 text-base font-semibold">{i.title}</h4>
                            <p className="text-sm">{i.description}</p>
                        </div>
                    ))}
                </div>
                <div>
                    <h3 className="text-xl font-semibold mb-10">
                        {t('banner.release-features.modal-content.upcoming-title')}
                    </h3>
                    <div className="grid lg:grid-cols-1 gap-10">
                        {upcomingBulletPoints.map((i) => (
                            <div key={i.id} className="flex flex-row items-start gap-8">
                                <div className="flex items-center justify-center">
                                    {Array.isArray(i.icon) ? (
                                        <Icon icon={i.icon as any} className="xl" />
                                    ) : (
                                        <img src={i.icon} alt={i.title} />
                                    )}
                                </div>
                                <div className="flex-1">
                                    <h4 className="mb-4 text-base font-semibold">{i.title}</h4>
                                    <p className="text-sm">{i.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-row-reverse gap-3">
                    {buttons.map((i) => (
                        <EezyButton
                            key={i.value}
                            className="v2-btn"
                            dark={i.type === 'primary'}
                            color={i.type === 'primary' ? 'eezy-green' : 'purple'}
                            onClick={() => onClickButton(i)}
                        >
                            {i.label}
                        </EezyButton>
                    ))}
                </div>
            </div>
        </StyledModal>
    );
};
EezyDataBannerModal.displayName = 'EezyDataBannerModal';

export default EezyDataBannerModal;
