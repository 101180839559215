import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';
import EezyPayIntroduction from './EezyPayIntroduction';
import { useNavigate } from 'react-router-dom';

const TempoWhiteBackground = styled.div`
    &::before {
        content: '';
        display: block;
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
    }
`;

type Props = object;

export default function EezyPayWelcome(_: Props) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const onClickCreate = () => {
        navigate('/sales-pages/create');
    };

    const onClickReadMore = () => {
        const url =
            i18n.language === 'fi'
                ? 'https://kevytyrittajat.eezy.fi/eezypay/nain-hyodynnat-eezypayta/'
                : 'https://kevytyrittajat.eezy.fi/en/eezypay-en/this-is-how-you-utilize-eezypay/';
        window.open(url, '_blank');
        navigate('/sales-pages');
    };

    return (
        <TempoWhiteBackground>
            <div className="relative py-5">
                <div className="flex flex-col xl:flex-row gap-10 items-center">
                    <div className="flex-1 basis-2/6">
                        <div className="max-w-xl xl:max-w-96 text-center xl:text-left">
                            <h1 className="text-4xl font-bold text-txt-display leading-normal mb-12 mt-12 xl:mt-0">
                                EezyPay
                            </h1>
                            <h4 className="font-semibold text-base text-gray-800 leading-normal mb-8">
                                {t('eezy-pay-welcome.headline')}
                            </h4>
                            <p className="text-base text-xs text-gray-800 leading-normal mb-16">
                                {t('eezy-pay-welcome.description')}
                            </p>
                            <div className="flex flex-col xl:flex-row gap-4">
                                <Button startIcon={['far', 'plus']} onClick={onClickCreate}>
                                    {t('eezy-pay-welcome.primary-button')}
                                </Button>
                                <Button
                                    variant="ghost"
                                    endIcon={['far', 'arrow-right']}
                                    onClick={onClickReadMore}
                                >
                                    {t('eezy-pay-welcome.secondary-button')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 basis-3/6">
                        <div className="px-14 py-10 rounded-3xl xl:bg-gray-100">
                            <div className="mb-9">
                                <h3 className="text-center font-semibold text-xl xl:text-txt-description xl:text-left">
                                    {t('eezy-pay-instruction.headline')}
                                </h3>
                            </div>
                            <EezyPayIntroduction />
                        </div>
                    </div>
                </div>
            </div>
        </TempoWhiteBackground>
    );
}
