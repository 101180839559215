import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { showModals } from 'actions/auth';
import { COLOR_GREYHOUND } from '../../styles/variables';
import { Modal, ModalContent } from './Modal';
import { GET_USER_DATA } from '../../containers/profile/queries';
import { useQuery } from '@apollo/client';
import { useUser } from 'queries/useUserQuery';
import { useTranslation } from 'react-i18next';

const IntroText = styled.p`
    color: ${COLOR_GREYHOUND};
`;

const DisabledMessageList = styled.ul`
    list-style-type: none; // Removes the default bullets
    margin: 0;
    padding: 0;

    li {
        position: relative;
        margin: 0;
        padding-left: 20px;
        color: ${COLOR_GREYHOUND};

        &:before {
            content: '•'; // You can use other symbols or even images
            position: absolute;
            left: 0;
            color:${COLOR_GREYHOUND} // Match the color with the text
            font-size: 1.2em; // Adjust the size
        }
    }
`;

const DisabledMessageItem = styled.li`
    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

type Props = {
    handleModalClose: () => void;
    handleConfirm: () => void;
    isOpen: boolean;
};

const EezyPayModal: React.FC<Props> = (props: Props) => {
    const { isOpen, handleModalClose } = props;

    const user = useUser();
    const { data: userData } = useQuery(GET_USER_DATA);

    const bankAccountNumber = userData?.userData?.bankAccountNumber;
    const hasAddress = !!(user?.mailingAddress?.street1 && user?.mailingAddress?.town);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = (type: string) => {
        handleModalClose();

        switch (type) {
            case 'isIdentified':
                dispatch(showModals(['AUTH']));
                break;
            case 'bankAccountNumber':
                navigate('/profile/salary');
                break;
            case 'hasAddress':
                navigate('/profile/contact');
                break;
        }
    };

    const getDisabledMessage = () => {
        const steps = [];

        if (!hasAddress) {
            steps.push({ text: t('eezy-pay-modal.add-address'), type: 'hasAddress' });
        }
        if (!bankAccountNumber) {
            steps.push({ text: t('eezy-pay-modal.add-bank-account'), type: 'bankAccountNumber' });
        }
        if (!user?.isIdentified) {
            steps.push({ text: t('eezy-pay-modal.complete-identification'), type: 'isIdentified' });
        }

        return (
            <div>
                <IntroText>{t('eezy-pay-modal.you-still-need-to')}</IntroText>
                <DisabledMessageList>
                    {steps.map((step) => (
                        <DisabledMessageItem
                            key={step.type}
                            onClick={() => {
                                handleClick(step.type);
                            }}
                        >
                            {step.text}
                        </DisabledMessageItem>
                    ))}
                </DisabledMessageList>
            </div>
        );
    };

    return (
        <Modal
            small
            id="modal-eezy-pay-about"
            title={t('eezy-pay-modal.title')}
            isOpen={isOpen}
            onClose={handleModalClose}
        >
            <ModalContent>
                <DisabledMessageList>{getDisabledMessage()}</DisabledMessageList>
            </ModalContent>
        </Modal>
    );
};

export default EezyPayModal;
