import { Outlet } from 'react-router-dom';
import Drawer from 'containers/nav/Drawer';

export const SalesPagesWrapper = () => {
    return (
        <Drawer>
            <Outlet />
        </Drawer>
    );
};
