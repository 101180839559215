import { getLocalStorage, setLocalStorage } from 'utils/user/userUtils';
import type { BannerStoredState } from './types';

const initialState = (version: number): BannerStoredState => ({
    closeTimes: 0,
    closeDate: null,
    visible: true,
    version,
});

export const FEATURES_BANNER_MODAL_BULLET_ICONS = ['chart-mixed', 'arrows-cross'];
export const FEATURES_BANNER_MODAL_UPCOMING_BULLET_ICONS = ['/imgs/banners/phone_bullet_point.png', 'coins'];

export const getBannerStoredState = (name: string, currentVersion: number): BannerStoredState => {
    const stored: BannerStoredState | null = getLocalStorage(name);

    // Handle case where there's no stored data
    if (!stored) {
        return { ...initialState(currentVersion) };
    }

    // We treat old data as version 0
    if (stored.version === undefined) {
        return {
            ...stored,
            version: 0,
        };
    }

    // If version doesn't match current, reset state
    if (stored.version !== currentVersion) {
        return { ...initialState(currentVersion) };
    }

    // Handle date conversion for existing data
    if (stored.closeDate) {
        stored.closeDate = new Date(stored.closeDate);
    }

    return stored;
};

export const setBannerCloseTimes = (name: string, times: number, version: number) => {
    const stored = getBannerStoredState(name, version);
    stored.closeTimes = times;
    stored.closeDate = new Date();
    stored.version = version;

    setLocalStorage(name, stored);
};

export const toggleBannerVisible = (name: string, visible: boolean, version: number) => {
    const stored = getBannerStoredState(name, version);
    stored.visible = visible;
    stored.version = version;
    setLocalStorage(name, stored);
};

export const resetBannerStoredState = (name: string, version: number) => {
    setLocalStorage(name, { ...initialState(version) });
};
