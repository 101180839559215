import moment from 'moment';
import { useState } from 'react';
import FullCampaignBanner from '../FullCampaignBanner';
import MiniFeaturedBanner from '../MiniFeaturedBanner';
import {
    getBannerStoredState,
    resetBannerStoredState,
    setBannerCloseTimes,
    toggleBannerVisible,
} from '../utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { BannerButton } from '../types';
import EezyDataBannerModal from '../EezyDataBannerModal';
import { CURRENT_BANNER_VERSION } from '../EezyDataBanner/constants';

const BannerStoreKeyName = 'EezyDataBanner';

const EezyDataBanner = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isOpenModal, setOpenModal] = useState(false);

    const [isTempClose, setTempClose] = useState(false);
    const from = t('banner.release-features.from-date');
    const to = t('banner.release-features.to-date');
    const image = t('banner.release-features.featured-image');
    const title = t('banner.release-features.title');
    const shortTitle = t('banner.release-features.short-title');
    const description = t('banner.release-features.description');
    const shortDescription = t('banner.release-features.short-description');
    const hasFullBanner = !!image;

    const buttons: BannerButton[] = t('banner.release-features.buttons', {
        returnObjects: true,
    }) as BannerButton[];

    if (isTempClose) {
        return null;
    }

    const inPeriod = moment().isBetween(from, to, 'day', '[]');

    if (!inPeriod) {
        return null;
    }

    const stored = getBannerStoredState(BannerStoreKeyName, CURRENT_BANNER_VERSION);

    if (!stored.visible) {
        return null;
    }

    if (stored.closeTimes >= 4) {
        if (moment(stored.closeDate).isAfter(from)) {
            return null;
        }

        resetBannerStoredState(BannerStoreKeyName, CURRENT_BANNER_VERSION);
    }

    const onClose = () => {
        setTempClose(true);
        setBannerCloseTimes(BannerStoreKeyName, stored.closeTimes + 1, CURRENT_BANNER_VERSION);
        toggleBannerVisible(BannerStoreKeyName, false, CURRENT_BANNER_VERSION);
    };

    const showModalReadMore = () => {
        setOpenModal(true);
    };

    const onClickButton = (item: BannerButton) => {
        if (item.value.startsWith('http')) {
            // Handle open external link
            window.open(item.value, '_blank');
        } else if (item.value?.startsWith('/')) {
            // Handle open internal link
            navigate(item.value);
        } else if (item.value === 'open_modal') {
            showModalReadMore();
        } else {
            console.warn('[Banner]', item.value);
        }
    };

    const DisplayBanner =
        hasFullBanner && stored.closeTimes % 2 === 0 ? FullCampaignBanner : MiniFeaturedBanner;

    return (
        <>
            <DisplayBanner
                image={image}
                title={title}
                shortTitle={shortTitle}
                description={description}
                shortDescription={shortDescription}
                buttons={buttons}
                onClose={onClose}
                onClickButton={onClickButton}
                onClickReadMore={() => onClickButton(buttons[buttons.length - 1])}
            />
            {isOpenModal && <EezyDataBannerModal onClose={() => setOpenModal(false)} />}
        </>
    );
};

export default EezyDataBanner;
