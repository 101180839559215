import {
    ACCEPT_EEZYPAY_TERMS,
    EEZYPAY_USER_DATA_LOADING,
    EEZYPAY_USER_DATA_LOADING_COMPLETE,
    EEZYPAY_USER_DATA_LOADING_ERROR,
    SALES_PAGE_SEARCH_QUERY,
    SUBSCRIPTION_END_DATE,
    TRANSACTIONS_SEARCH_QUERY,
} from 'actionTypes';

export interface IEezyPayState {
    salesPageSearchQuery: string;
    transactionsSearchQuery: string;
    eezyPayTermsAccepted: boolean;
    campwireTermsAccepted: boolean;
    userLoadingComplete: boolean;
    userState?: number;
}

const initialState: IEezyPayState = {
    salesPageSearchQuery: '',
    transactionsSearchQuery: '',
    eezyPayTermsAccepted: false,
    campwireTermsAccepted: false,
    userLoadingComplete: false,
};

export default (
    // biome-ignore lint/style/useDefaultParameterLast: Redux will be eventually removed
    state = initialState,
    action: {
        payload: { searchQuery: string };
        type: string;
    },
) => {
    switch (action.type) {
        case SALES_PAGE_SEARCH_QUERY:
            return {
                ...state,
                ...action.payload,
            };
        case TRANSACTIONS_SEARCH_QUERY:
            return {
                ...state,
                ...action.payload,
            };
        case EEZYPAY_USER_DATA_LOADING:
        case EEZYPAY_USER_DATA_LOADING_COMPLETE:
        case EEZYPAY_USER_DATA_LOADING_ERROR:
        case SUBSCRIPTION_END_DATE:
            return {
                ...state,
                ...action.payload,
            };
        case ACCEPT_EEZYPAY_TERMS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
