import { useEffect, type ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

export default function ModalContainer(props: Props) {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.removeProperty('overflow');
        };
    }, []);

    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-800/[0.23] grid items-center justify-center overflow-y-auto">
            {props.children}
        </div>
    );
}
