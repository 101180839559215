import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';
import EezyPayIntroduction from 'containers/eezypay/EezyPayIntroduction';

type Props = {
    close: () => void;
};

export default function EezyPayInstructionModal(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="bg-white rounded-xl shadow-lg text-gray-800">
            <div className="flex items-center justify-between p-6">
                <h4 className="font-semibold text-base">{t('eezy-pay-instruction.headline')}</h4>
                <button type="button" className="px-3" onClick={props.close}>
                    <FontAwesomeIcon icon={['far', 'xmark']} />
                </button>
            </div>
            <div className="max-w-4xl p-6">
                <EezyPayIntroduction />
            </div>
            <div className="p-6 flex justify-end">
                <Button variant="outline" onClick={props.close}>
                    {t('close')}
                </Button>
            </div>
        </div>
    );
}
