import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import type { IAmountWithVat, IReceiptCost } from '../../../../shared/src/types/costs';
import { EezyButton } from 'components/Buttons';
import {
    DatePicker,
    FormInput,
    FormNumberInput,
    FormRow,
    FormSection,
    FormSelect,
    SplitRow,
} from 'components/form';
import type { IDropdownOption } from 'components/form/AutocompleteDropdown';
import { FormLabel } from 'components/form/FormLabel';
import { Icon } from 'components/Icon';
import { P } from 'components/textElements';
import { COLOR_GREYHOUND } from 'styles/variables';
import { formatCents, formatDateISO } from 'utils';
import {
    COST_VAT_PERCENTAGES,
    getReceipt,
    getTravelReceiptType,
    type ICostComponentProps,
    isSupplyReceipt,
    RECEIPT,
    vatOptions,
} from 'utils/costs/costLogic';

export const ReceiptDetails = (props: ICostComponentProps) => {
    const { t } = useTranslation();
    const { dispatch } = props;
    const receipt: IReceiptCost | undefined = getReceipt(props.currentCost, props.travelCostTemp);

    const withoutVAT =
        !props.invoice!.isGroupInvoice &&
        props.invoice!.template === 'domestic' &&
        props.invoice!.totalWithVat === props.invoice!.total;

    if (!receipt) {
        return null;
    }

    const isTemp = () => {
        return !!props.travelCostTemp;
    };

    const saveReceiptCost = (payload: any) => {
        if (isTemp()) {
            // travel costs are temporary until ADD_TRAVEL_COST_TEMP action called
            dispatch({
                payload: { ...payload },
                type: 'SAVE_TRAVEL_COST_TEMP',
            });
        } else {
            dispatch({ payload, type: 'SAVE_COST' });
        }
    };

    const usedVats = (): number[] => {
        if (withoutVAT) {
            return COST_VAT_PERCENTAGES.filter((item) => item !== 0);
        }

        // only 1 row for each vat percentage
        if (!receipt.amountsWithVats) {
            return [];
        }
        return receipt.amountsWithVats?.map((r) => {
            if (typeof r.vatPercent === 'number') {
                return r.vatPercent;
            }
            return Number.parseInt(r.vatPercent, 10);
        });
    };

    const vatSelectOptions = () => {
        return vatOptions(usedVats());
    };

    const handleAddRow = () => {
        const vat = vatSelectOptions().filter((v: IDropdownOption) => !v.disabled)[0];
        const amountsWithVats = [
            ...(receipt.amountsWithVats || []),
            { vatPercent: Number.parseFloat(vat.value), amount: 0 },
        ];
        saveReceiptCost({ amountsWithVats });
    };

    const handleDeleteRow = (index: number) => {
        if (!receipt.amountsWithVats) {
            return;
        }
        const amountsWithVats = receipt.amountsWithVats.filter((_r: IAmountWithVat, i) => i !== index);
        saveReceiptCost({ amountsWithVats });
    };

    const handleUpdateRow = (o: any, index: number) => {
        if (!receipt.amountsWithVats) {
            return;
        }
        const amountsWithVats = receipt.amountsWithVats.map((r: IAmountWithVat, i) => {
            if (i === index) {
                return { ...r, ...o };
            }
            return r;
        });
        saveReceiptCost({ amountsWithVats });
    };

    return (
        <div>
            <FormSection>
                <SplitRow>
                    {((!isSupplyReceipt(receipt) && !isTemp()) ||
                        (receipt.description && !getTravelReceiptType(receipt) && isTemp())) && (
                        <FormInput
                            name="cost-title"
                            label={t('costs.receipt-label') || ''}
                            placeholder={
                                props.step === RECEIPT
                                    ? t('costs.receipt-placeholder-travel') || ''
                                    : t('costs.receipt-placeholder-cost') || ''
                            }
                            required
                            onChange={(description) => saveReceiptCost({ description })}
                            style={{ flexGrow: 2 }}
                            value={receipt.description}
                        />
                    )}
                    <FormRow>
                        <FormLabel htmlFor="cost-date" value={receipt.purchaseDate}>
                            {props.step === RECEIPT ? t('costs.buy-date-travel') : t('costs.buy-date')}
                        </FormLabel>
                        <DatePicker
                            disableToolbar
                            label={props.step === RECEIPT ? t('costs.buy-date-travel') : t('costs.buy-date')}
                            icon={['far', 'calendar-day']}
                            id="cost-date"
                            onChange={(d: Date | null) => {
                                if (d) {
                                    saveReceiptCost({
                                        purchaseDate: formatDateISO(d),
                                    });
                                }
                            }}
                            placeholder=""
                            required
                            value={receipt.purchaseDate}
                        />
                    </FormRow>
                </SplitRow>

                {receipt.amountsWithVats?.map((row: IAmountWithVat, i) => {
                    return (
                        <SplitRow style={{ alignItems: 'flex-end' }} key={`row-${i}`}>
                            <FormRow>
                                <FormLabel id={`cost-total-${i}`} value={row.amount}>
                                    {t('costs.total-with-vat')}
                                </FormLabel>
                                <div
                                    style={{
                                        alignItems: 'baseline',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginRight: 5,
                                    }}
                                >
                                    <FormNumberInput
                                        isEur={true}
                                        endAdornment={'€'}
                                        name={`cost-total-${i}`}
                                        onChange={(amount) => handleUpdateRow({ amount }, i)}
                                        required
                                        value={row.amount}
                                    />
                                </div>
                            </FormRow>
                            <FormSelect
                                name={`cost-alv-${i}`}
                                label={t('costs.vat') || ''}
                                aria-label={t('costs.vat')}
                                options={vatSelectOptions()}
                                onChange={(vatPercent) =>
                                    handleUpdateRow({ vatPercent: Number.parseFloat(vatPercent) }, i)
                                }
                                required
                                showIcon
                                value={row.vatPercent ? row.vatPercent.toString() : '0'}
                            />
                            <EezyButton
                                aria-label={
                                    t('costs.vat-remove', {
                                        total: formatCents(row.amount),
                                        vat: row.vatPercent,
                                    }) || ''
                                }
                                color="purple"
                                disabled={receipt.amountsWithVats?.length === 1}
                                onClick={() => handleDeleteRow(i)}
                                square
                                style={{ marginLeft: 5, padding: 0 }}
                                width={30}
                            >
                                <Icon icon={['far', 'trash-alt']} color="black" />
                            </EezyButton>
                            <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
                                {i === (receipt.amountsWithVats || []).length - 1 ? (
                                    <EezyButton
                                        color="purple"
                                        disabled={(receipt.amountsWithVats || []).length >= 4}
                                        square
                                        onClick={handleAddRow}
                                        width={110}
                                    >
                                        {t('costs.add-vat')}
                                    </EezyButton>
                                ) : (
                                    <span style={{ minWidth: 115 }} />
                                )}
                            </Box>
                        </SplitRow>
                    );
                })}
                <Box sx={{ display: { sm: 'none', xs: 'block' } }}>
                    <FormSection>
                        <EezyButton
                            color="black"
                            disabled={(receipt.amountsWithVats || []).length >= 4}
                            onClick={handleAddRow}
                            square
                            style={{
                                alignSelf: 'flex-end',
                                width: '120px',
                                padding: '8px 16px',
                            }}
                        >
                            {t('costs.add-vat')}
                        </EezyButton>
                    </FormSection>
                </Box>

                <P color={COLOR_GREYHOUND} style={{ marginTop: 30 }}>
                    {t(withoutVAT ? 'costs.receipt-note-no-VAT' : 'costs.receipt-note-1')}
                </P>

                <P color={COLOR_GREYHOUND} style={{ marginTop: 15 }}>
                    {t('costs.receipt-note-2')}
                </P>
            </FormSection>
        </div>
    );
};
